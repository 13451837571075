import extend from 'extend';
import _ from 'underscore';

export default {
  props: {
    value: {
      type: String,
      required: false,
      default: `-1`,
    },
  },
  data() {
    return {
      selected_value: `-1`,

      entity_list: [
        {value: `0`, text: `全部`, active: false},
        {value: `1`, text: `男人`, active: false},
        {value: `2`, text: `女人`, active: false},
      ],
    };
  },
  watch: {
    value(pNewValue, pOldValue) {
      const __this = this;
      __this.selected_value = pNewValue;
    },
    selected_value(pNewValue, pOldValue) {
      const __this = this;
      __this.$emit('input', pNewValue);
    },
  },
  methods: {
    __btn_entity_click(pEntity) {
      const __this = this;

      _.each(__this.entity_list, function (m) {
        m.active = false;
      });
      pEntity.active = true;

      __this.selected_value = pEntity.value;
    },

    get_selected_entity() {
      const __this = this;
      const value = __this.value;
      const entity_list = __this.entity_list;

      const query = _.chain(entity_list)
        .find(function (m) {
          return m.value === value;
        })
        .value()
      ;
      if (!query) {
        const msg = 'get_selected_entity parameter error, code=001';
        console.error(msg);
        console.trace();
        return query;
      }
      const r = extend(true, {}, query);
      return r;
    },
  },
  created() {
    const __this = this;

    const entity_list = __this.entity_list;

    _.each(entity_list, function (m) {
      m.active = false;
    });

    const query = _.chain(entity_list)
      .find(function (m) {
        return m.value === __this.value;
      })
      .value()
    ;
    if (query) {
      query.active = true;
      __this.selected_value = query.value;
      return;
    }

    //v-model的值非法时，选取第一个值
    if (entity_list.length > 0) {
      const first_entity = entity_list[0];
      __this.selected_value = first_entity.value;
      first_entity.active = true;
    }
  },
}
