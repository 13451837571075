import VModelTest001 from '@/component/v-model-test-001/index.vue'
import VModelTest002 from '@/component/v-model-test-002/index.vue'

export default {
  components: {
    VModelTest001,
    VModelTest002,
  },
  data() {
    return {
      // value_VModelTest001: ``,
      value_VModelTest001: `1`,

      // value_VModelTest002: null,
      value_VModelTest002: {
        value: `1`,
        // text: `男人`,
        // active: false
      },
    };
  },
  methods: {
    __btn_get_value_VModelTest001_click() {
      const __this = this;
      console.log(__this.value_VModelTest001);
      console.log(__this.$refs.aVModelTest001.get_selected_entity());
    },
    __btn_get_value_VModelTest002_click() {
      const __this = this;
      console.log(__this.value_VModelTest002);
    },
  },
  created() {
    // const __this = this;
  },
  activated() {
    // const __this = this;
  },
  deactivated() {
    // const __this = this;
  },
}
