import _ from 'underscore';

export default {
  props: {
    value: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      selected_entity: null,

      entity_list: [
        {value: `0`, text: `全部`, active: false},
        {value: `1`, text: `男人`, active: false},
        {value: `2`, text: `女人`, active: false},
      ],
    };
  },
  watch: {
    value(pNewValue, pOldValue) {
      const __this = this;
      __this.selected_entity = pNewValue;
    },
    selected_entity(pNewValue, pOldValue) {
      const __this = this;
      __this.$emit('input', pNewValue);
    },
  },
  methods: {
    __btn_entity_click(pEntity) {
      const __this = this;

      _.each(__this.entity_list, function (m) {
        m.active = false;
      });
      pEntity.active = true;

      __this.selected_entity = pEntity;
    },
  },
  created() {
    const __this = this;

    const entity_list = __this.entity_list;

    _.each(entity_list, function (m) {
      m.active = false;
    });

    const query = _.chain(entity_list)
      .find(function (m) {
        if (!__this.value) return false;
        return m.value === __this.value.value;
      })
      .value()
    ;
    if (query) {
      query.active = true;
      __this.selected_entity = query;
      return;
    }

    //v-model的值非法时，选取第一个值
    if (entity_list.length > 0) {
      const first_entity = entity_list[0];
      __this.selected_entity = first_entity;
      first_entity.active = true;
    }

  },
}
